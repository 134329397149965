<template>
    <div id="bg"></div>
</template>

<script>
export default {
    //  props:["parenth"],
    name: "bg_slide",
    data: function () {
        return {
            window: {
                width: 0,
                height: 0,
            },

            refreshDuration: 10000,
            refreshTimeout: null,
            numPointsX: null,
            numPointsY: null,
            unitWidth: null,
            unitHeight: null,
            points: [],
            parenth: 0,
        };
    },
    components: {},

    methods: {
        onResize() {
            this.setSize();
            if (document.querySelector("#bg svg") !== null) {
                document.querySelector("#bg svg").remove();
            }
            clearTimeout(this.refreshTimeout);
            this.onLoad();
        },
        setSize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        onLoad() {
            console.log("onload");
            this.parenth = document.querySelector("section.hello");
            this.parenth = this.parenth !== null ? this.parenth.clientHeight : 800;
            let h = this.window.width > 1264 ? 810 : this.parenth + 10;
            let w = this.window.width;
            let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
            svg.setAttribute("width", w);
            svg.setAttribute("height", h);
            document.querySelector("#bg").appendChild(svg);

            let unitSize = (w + h) / 20;
            this.numPointsX = Math.ceil(w / unitSize) + 1;
            this.numPointsY = Math.ceil(h / unitSize) + 1;
            this.unitWidth = Math.ceil(w / (this.numPointsX - 1));
            this.unitHeight = Math.ceil(h / (this.numPointsY - 1));

            this.points = [];

            for (var y = 0; y < this.numPointsY; y++) {
                for (var x = 0; x < this.numPointsX; x++) {
                    this.points.push({ x: this.unitWidth * x, y: this.unitHeight * y, originX: this.unitWidth * x, originY: this.unitHeight * y });
                }
            }

            this.randomize();

            for (let i = 0; i < this.points.length; i++) {
                if (this.points[i].originX != this.unitWidth * (this.numPointsX - 1) && this.points[i].originY != this.unitHeight * (this.numPointsY - 1)) {
                    let topLeftX = this.points[i].x;
                    let topLeftY = this.points[i].y;
                    let topRightX = this.points[i + 1].x;
                    let topRightY = this.points[i + 1].y;
                    let bottomLeftX = this.points[i + this.numPointsX].x;
                    let bottomLeftY = this.points[i + this.numPointsX].y;
                    let bottomRightX = this.points[i + this.numPointsX + 1].x;
                    let bottomRightY = this.points[i + this.numPointsX + 1].y;

                    var rando = Math.floor(Math.random() * 2);

                    for (var n = 0; n < 2; n++) {
                        var polygon = document.createElementNS(svg.namespaceURI, "polygon");

                        if (rando == 0) {
                            if (n == 0) {
                                polygon.point1 = i;
                                polygon.point2 = i + this.numPointsX;
                                polygon.point3 = i + this.numPointsX + 1;
                                polygon.setAttribute("points", topLeftX + "," + topLeftY + " " + bottomLeftX + "," + bottomLeftY + " " + bottomRightX + "," + bottomRightY);
                            } else if (n == 1) {
                                polygon.point1 = i;
                                polygon.point2 = i + 1;
                                polygon.point3 = i + this.numPointsX + 1;
                                polygon.setAttribute("points", topLeftX + "," + topLeftY + " " + topRightX + "," + topRightY + " " + bottomRightX + "," + bottomRightY);
                            }
                        } else if (rando == 1) {
                            if (n == 0) {
                                polygon.point1 = i;
                                polygon.point2 = i + this.numPointsX;
                                polygon.point3 = i + 1;
                                polygon.setAttribute("points", topLeftX + "," + topLeftY + " " + bottomLeftX + "," + bottomLeftY + " " + topRightX + "," + topRightY);
                            } else if (n == 1) {
                                polygon.point1 = i + this.numPointsX;
                                polygon.point2 = i + 1;
                                polygon.point3 = i + this.numPointsX + 1;
                                polygon.setAttribute("points", bottomLeftX + "," + bottomLeftY + " " + topRightX + "," + topRightY + " " + bottomRightX + "," + bottomRightY);
                            }
                        }
                        polygon.setAttribute("fill", "rgba(0,0,0," + Math.random() / 3 + ")");
                        let animate = document.createElementNS("http://www.w3.org/2000/svg", "animate");
                        animate.setAttribute("fill", "freeze");
                        animate.setAttribute("attributeName", "points");
                        animate.setAttribute("dur", this.refreshDuration + "ms");
                        animate.setAttribute("calcMode", "linear");
                        polygon.appendChild(animate);
                        svg.appendChild(polygon);
                    }
                }
            }

            this.refresh();
        },
        randomize() {
            for (let i = 0; i < this.points.length; i++) {
                if (this.points[i].originX != 0 && this.points[i].originX != this.unitWidth * (this.numPointsX - 1)) {
                    this.points[i].x = this.points[i].originX + Math.random() * this.unitWidth - this.unitWidth / 2;
                }
                if (this.points[i].originY != 0 && this.points[i].originY != this.unitHeight * (this.numPointsY - 1)) {
                    this.points[i].y = this.points[i].originY + Math.random() * this.unitHeight - this.unitHeight / 2;
                }
            }
        },
        refresh() {
            if (document.querySelector("#bg") !== null) {
                if (document.querySelector("#bg svg") === null) {
                    this.onLoad();
                } else {
                    this.randomize();
                    for (let i = 0; i < document.querySelector("#bg svg").childNodes.length; i++) {
                        let polygon = document.querySelector("#bg svg").childNodes[i];
                        let animate = polygon.childNodes[0];
                        if (animate.getAttribute("to")) {
                            animate.setAttribute("from", animate.getAttribute("to"));
                        }
                        animate.setAttribute(
                            "to",
                            this.points[polygon.point1].x +
                                "," +
                                this.points[polygon.point1].y +
                                " " +
                                this.points[polygon.point2].x +
                                "," +
                                this.points[polygon.point2].y +
                                " " +
                                this.points[polygon.point3].x +
                                "," +
                                this.points[polygon.point3].y
                        );
                        animate.beginElement();
                    }
                    const dis = this;
                    this.refreshTimeout = setTimeout(() => {
                        dis.refresh();
                    }, this.refreshDuration);
                }
            }
        },
    },
    created() {
        // window.addEventListener('resize', this.handleResize);
        this.setSize();

        window.onload = this.onLoad;
        window.onresize = this.onResize;
    },
};
</script>

<style>
#bg {
    background: radial-gradient(ellipse at center, #4a3fc3 0%, #244e5a 100%);
    overflow: hidden;
    /* position: fixed;*/
    mix-blend-mode: color-burn;
}
@media (max-width: 1264px) {
}
</style>
