<template>
    <v-container class="dark-back text-color d-block pt-8 mt-10 mb-10">
        <v-row>
            <v-col md="4" lg="3" class="d-none d-md-block">
                <h2 class="uppercase mb-10 mt-10 underliner">##&en Infrastructure ##&hu Infrastruktura ##</h2>
                <br />
                <img :src="require(`@/assets/cube_d250.svg`)" />
            </v-col>
            <v-col md="7" lg="8" class="text-left py-4">
                <h2 class="mb-4">##&en Networks ##&hu Hálózatépítés ##</h2>
                <p class="mb-8">
                    ##&en We build and maintain ethernet networks for our customers. This includes standard Ethernet over copper and optical cables, and dedicated networks, such as
                    camera systems. From planning cable channels to configuring routers, all in one hand. ##&hu Optikai és rézhálózat építés és üzemeltetés. Általános és célhálózatok tervezése és
                    kivitelezése. Kábelnyomvonal-tervezéstől routerkonfigurációig, minden egy kézből. ##
                </p>

                <h2 class="mb-4">##&en Hangmaffia - Install and rental ##&hu Hangmaffia - Installáció és eszközbérbeadás ##</h2>
                <p class="mb-8">
                    ##&en We also build installations of low-voltage electronic systems, such as sound,- light,- and visual-systems. It is running under our seperate brand name, called Hangmaffia.<br />
                    In fact, most of our modern technology is digital, so even a PA/FOH is networked digitally. Actually the install and rental section in our company is huge! ##&hu Finomeletronikai eszközök telepítése, üzembehelyezése,
                    üzemeltetése. Hangtechnika, fénytechnika, színpadtechnika, vizuáltechnika. Mivel már a minket körülvevő technikai eszközök döntő többsége digitális, minden
                    programozható, és programozást igényel. Manapság már modern erősítő is hálózati kapcsolaton működik. A Hangmaffia brand gyakorlatilag ezzel foglalkozik. ##
                </p>

                <h2 class="mb-4">19" ##&en Workshop ##&hu Műhely ##</h2>
                <p class="mb-8">
                    ##&en Rack's. Custom Flightcases. Pre-created parts for installation. Special cables. You name it, we build it. Our workshop has all the tools, the materials, and the know-how. ##&hu Rekkek, finomelektronikai szállítási
                    konténerek, speciális kábelek. Csak meg kell nevezni, megépítjük. Műhelyünkben a megfelelő szerszámok, és anyagok rendelkezésre állnak, gyakorlatban tapasztalt munkaerővel. ##
                </p>

                <h2 class="mb-4">##&en Technical Managment ##&hu Technikai vezetés ##</h2>
                <p class="mb-8">
                    ##&en On-site Asset and resource managment, coordinating human and electronic resources to keep the buisness going 24/7, 365 days a year. ##&hu Helyszíni eszköz
                    és emberi erőforrás managment, munka és eszköz-koordináció, üzemeltetés napi 24 órában, az év 365 napján. ##
                </p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "infrastructure",
    route:  { path: "/infrastructure" },
};
</script>
