<template>
    <v-app-bar dark fixed>
        <v-toolbar-items class="hidden-sm-and-down">
            <v-btn text to="/">HOME</v-btn>
            

            <!-- <v-btn ext v-on:click="$root.login_dialog.open();" @click="to_log">##&en Login ##&hu Bejelentkezés ##</v-btn> -->
        </v-toolbar-items>
        <v-spacer></v-spacer>

        <language_selector></language_selector>

        <v-btn icon>
            <img :src="require('@/assets/D250_Laboratories.svg')" width="50px" />
        </v-btn>

        <v-menu bottom left class="hidden-md-and-up">
            <template v-slot:activator="{ on }">
                <v-app-bar-nav-icon v-on="on" class="hidden-md-and-up"></v-app-bar-nav-icon>
            </template>

            <v-list>
                <v-list-item to="/">
                    <v-list-item-title>HOME</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-app-bar>
</template>

<script>
    /*
    <v-btn text to="/price">##&hu Árlista ##&en Price ##</v-btn>
            <v-btn text to="/referencies">##&hu Referenciák ##&en Referencies ##</v-btn>
            <v-btn v-if="!$store.state.socket.isConnected" text v-on:click="$root.login_dialog.open()">##&en Login ##&hu Bejelentkezés ##</v-btn>
            <v-btn v-if="$store.state.socket.isConnected" text to="/settings">Settings</v-btn>
            <v-btn v-if="$store.state.socket.isConnected" text to="/dashboard">Dashboard</v-btn>
            <v-btn v-if="$store.state.socket.isConnected" text to="/profile">Profile</v-btn>
            <v-btn v-if="$store.state.socket.isConnected" text to="/ssh-keys">SSH KEYS</v-btn>
    */
    
import language_selector from "@/multilanguage-components/LanguageSelector.vue";

export default {
    name: "navigation",
    data: function () {
        return {};
    },
    components: {
        language_selector,
    },
    methods: {
        to_log() {
            this.$store.state.runtime = "log";
        },
        to_reg() {
            this.$store.state.runtime = "reg";
        },
    },
};
</script>

<style scope>
.v-btn--active {
    background: var(--lighten) !important;
}
</style>
