<template>
    <v-app id="app" class="app" data-app>
        <app_navigation />
        <app_main />
          <home_footer/> 
        
    </v-app>
</template>

<script>

import app_navigation from "@/navigation.vue";
import app_main from "@/main.vue";
//import login_dialog from "@/components/LoginDialog.vue";
import home_footer from "@/home_footer.vue";
export default {
    name: "app",

    data: function () {
        return {};
    },
    components: {
        app_navigation,
        app_main,
        //login_dialog, <login_dialog ref="login_dialog" />
        home_footer
    },
    mounted() {
        this.$root.login_dialog = this.$refs.login_dialog;
    },
};
</script>

<style scoped>

</style>
