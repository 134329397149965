<template>
    <section class="relative dark-bg">
        <v-container fill-height dark>
            <v-layout>
                <v-row justify="center" no-gutters>
                    <v-col md="6" lg="5" xl="3" cols="12" class="pa-5 desc-cols" v-for="(n, i) in cols" :key="i">
                        <v-card dark class="theme--dark pa-2 h-100" style="max-width: 360px; width: 360px; margin: auto" @click.native="open(n.link)">
                            <div class="pa-5 img-cont">
                                <v-responsive :aspect-ratio="6 / 6"><img :src="require(`@/assets/${n.img}`)" class="absolute" /></v-responsive>
                            </div>
                            <v-card-title class="text-center headline" style="display: block">
                                <span class="underliner-simple">{{ n.title }}</span>
                            </v-card-title>
                            <v-card-text style="min-height: 60px">{{ n.text }}</v-card-text>
                            <v-card-text class="pt-0 pb-8"><v-btn color="#938eaf">##&en More ##&hu Tovább ##</v-btn></v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-layout>
        </v-container>
    </section>
</template>

<script>
export default {
    name: "slide_desc",
    data: function () {
        return {
            cols: [
                {
                    title: "##&en Server Administration ##&hu Szerver Adminisztráció ##",
                    img: "server_d250.svg",
                    text: "LINUX ##&en System Administration Services ##&hu Rendszergazda szolgáltatások ##",
                    link: "/sysadmin",
                },
                {
                    title: "##&en MicroCloud WebHosting ##&hu MicroCloud WebHosting ##",
                    img: "cloud_d250.svg",
                    text: "##&en Web-hosting service provider ##&hu Tárhely és felhőszolgáltatások ##",
                    link: "/hosting",
                },
                {
                    title: "##&en Application development ##&hu Applikáció Fejlesztés ##",
                    img: "laptop_d250.svg",
                    text: "##&en Interactive web applications for businesses ##&hu Webes alkalmazások cége igényei szerint ##",
                    link: "/development",
                },
                {
                    title: "Hardware ##&en Infrastucture ##&hu Infrastruktúra ##",
                    img: "cube_d250.svg",
                    text: "##&en Sound, Light, Network ##&hu Hang, Fény, Hálózatépítés ##",
                    link: "/infrastructure",
                },
            ],
        };
    },
    created: function () {},
    methods: {
        open(link) {
            this.$router.push(link);
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.img-cont {
    max-width: 200px;
    margin: auto;
}
.v-responsive img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

#app .theme--dark.v-card,
#app .theme--light.v-card,
#app .theme--light.v-list {
    background: #3b3a48d9 !important;
    box-shadow: 0 0 15px #0000004f;
    border-radius: 5px;
}
</style>
