<template>
    <div id="home_ve_text" class="vert-center relative text-left cardbox">
        <div class="pa-8" style="height: 288px">
            <div class="d-flex justify-center">
                <img :src="require('@/assets/ve_white.svg')" class="ve-logo" />
                <div>
                    <h1 class="display-1 white--text font-weight-bold">##&en Containerfarm ##&hu Konténerfarm ##</h1>
                    <div class="header-text">##&en From developers, for developers ##&hu Fejlesztőktől fejlesztőknek ##</div>
                </div>
            </div>
            <div class="header-text">
                <br />
                ##&en Start a project, or two, as many as you want. Whatever environment you need, whatever languages you use. <br />Everything is up to date, so you can start using
                it. ##&hu Egyszerűen indíthatsz egy projektet, vagy kettőt, vagy bármennyit. Bármilyen nyelven, környezetben. <br />
                Minden ami kell, naprakészen áll rendelkezésre hogy használatba vehesd. ##
            </div>
            <div class="mt-5"><v-btn color="primary" to="/ve-farm">##&en Details ##&hu Nézzük tovább ##</v-btn></div>
        </div>
        <div class="v-card white--text theme--dark tech-card" style="height: 320px">
            <div class="d-flex w-100 fill-height tech-cont">
                <v-responsive v-for="(n, i) in active_techs" :key="i" class="tech-logos pa-2 d-flex text-center" justify="center" :aspect-ratio="1 / 1" style="width: 20%">
                    <v-icon dark :class="['icon-3d']" :ref="i + '_3dicon'"> fab {{ n }}</v-icon>
                </v-responsive>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "home_ve_layout",
    data: function () {
        return {
            techs: [
                "fa-fedora",
                "fa-js",
                "fa-vuejs",
                "fa-linux",
                "fa-php",
                "fa-angular",
                "fa-node",
                "fa-react",
                "fa-wordpress",
                "fa-git",
                "fa-sass",
                "fa-joomla",
                "fa-bootstrap",
                "fa-css3",
                "fa-laravel",
                "fa-magento",
                "fa-less",
                "fa-java",
                "fa-html5",
                "fa-redhat",
                "fa-npm",
                "fa-html5",

                "fa-html5",

                // TODO azt hiszem az utolsó sosem látszik
            ],
            active_techs: [],
        };
    },
    mounted() {
        let _this = this;

        let ms = 2000;

        let i = 0;
        let icon_i = 0;

        _this.active_techs = this.techs.slice(0, 10);

        setInterval((s) => {
            icon_i = Math.floor(Math.random() * (_this.techs.length - 1));

            while (_this.active_techs.indexOf(_this.techs[icon_i]) >= 0) {
                icon_i = Math.floor(Math.random() * (_this.techs.length - 1));
            }

            _this.$set(_this.active_techs, i, _this.techs[icon_i]);

            i = Math.floor(Math.random() * 10);
            //ms = Math.floor(Math.random() * 2000) + 500;

            // TODO - ezt itt nem teljesen látom mitől undefined néha
            if (!_this.$refs[i + "_3dicon"][0]) return;

            let cl = _this.$refs[i + "_3dicon"][0].$el.classList;

            setTimeout((so) => {
                cl.add("show_anim");
            }, 1500);
            setTimeout((so) => {
                cl.remove("show_anim");
                cl.add("active-anim");
            }, 2000);
            setTimeout((so) => {
                cl.remove("active-anim");
            }, 5000);
        }, ms);
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.header-text {
    text-shadow: 0 0 44px #172238;
    font-family: Roboto, sans-serif !important;
}
.tech-cont {
    flex-wrap: wrap;
    align-items: center;
}
.tech-logos i {
    font-size: 50px !important;
    margin: auto;
    position: relative !important;
    align-items: center !important;
    display: flex !important;
    justify-content: center !important;
    transform: translateY(-50%);
    top: 50%;
}
#ve-logo {
    filter: brightness(100) drop-shadow(0 0 45px #172238);
}
.ve-logo {
    position: relative;
    max-width: 100px;
    width: auto;
    height: auto;
    margin-right: 10px;
}

section.hello > .container {
    max-width: 1200px;
}
.tech-logos > .v-responsive__content {
    border-radius: 50%;
    border-radius: 50%;
    /* border: 2px solid #8f8fb3;*/
    padding: 15px;
}

/* effect */
:root {
    --transparent: rgba(200, 200, 200, 0);
    --shadow: rgba(0, 0, 0, 0.75);
    --flare: rgba(250, 250, 250, 0.75);
}

.icon-3d {
    padding: 10px;
    /* -webkit-animation: icon3d 4000ms 10; */
    /*  animation: icon3d 4000ms 10;*/
    color: #fff;
    opacity: 1;
    transition: 0.5s opacity linear;
}
.icon-3d:hover {
    -webkit-animation: icon3d 5000ms infinite;
    animation: icon3d 5000ms infinite;
}

.icon-3d.active-anim {
    -webkit-animation: icon3d 5000ms infinite;
    animation: icon3d 5000ms infinite;
}
.show_anim {
    opacity: 0;
    transition: 0.5s opacity linear;
}
@keyframes icon3d {
    0% {
        text-shadow: 0px 0px 0px var(--transparent), 0px 0px 0px var(--transparent);
    }
    10% {
        text-shadow: -3px -3px 5px var(--flare), 5px 4px 5px var(--shadow);
    }
    90% {
        text-shadow: 0px 0px 20px var(--transparent), 0px 0px 20px var(--transparent);
    }
    100% {
        text-shadow: 0px 0px 0px var(--transparent), 0px 0px 0px var(--transparent);
    }
}
@media (max-width: 1264px) {
    .home_panels {
        max-width: 600px;
        margin: auto;
    }
    #home_text * {
        text-align: center;
    }
    .tech-card {
        height: auto !important;
    }
}
</style>
