<template>
    <v-container class="dark-back text-color d-block pt-8 mt-10 mb-10">
        <v-row>
            <v-col md="4" lg="3" class="d-none d-md-block">
                <h2 class="uppercase mb-10 mt-10 underliner">##&en Cloud Hosting ##&hu Cloud Hosting ##</h2>
                <br />
                <img :src="require(`@/assets/cloud_d250.svg`)" />
            </v-col>
            <v-col md="7" lg="8" class="text-left py-4">
                <h2 class="mb-4">Domain</h2>
                <p class="mb-8">
                    ##&en A page on the web is identified by it's domain name. A domain name can be bought, the process is called domain registration, and is mostly a bourocratic
                    task. As a domain reseller, we can handle domain registration issues, such as registration or transfers. ##&hu Egy weblapot a címén keresztül lehet elérni. Ez a
                    cím megvásárolható, ez a domain regisztráció, ami többnyire büroratikus folyamata. Cégünk domain viszonteladóként intézi regisztrációs, domain átregisztrációs
                    és technikai domain-kezelési ügyeit, amennyiben erre igényt tart. ##
                </p>

                <h2 class="mb-4">##&en Hosting ##&hu Tárhely ##</h2>
                <p class="mb-8">
                    ##&en Content on the internet is just bits and bytes, that can be reached over a domain name and are served by a host. We run a whole infrastructure associated
                    to serving all common services, like DNS, E-mail, web and certificates. ##&hu Az internetes tartalmak bitek és byte-ok, melyeknek egy kiszolgálón keresztül
                    elérhetőnek kell lennie címén keresztül. Szervereinken teljes infrastruktúrát üzemeltetünk ahhoz, hogy ez a folyamat minden modern szempontnak megfelelően
                    elérhető legyen. Minden amire szükség van, elérhető. DNS, E-mail, web, tanusítványok. ##
                </p>

                <h2 class="mb-4">##&en As root in containers ##&hu A konténerekben root jogokkal ##</h2>
                <p class="mb-8">
                    ##&en Internet service providers use a technology, in our case it is the use of systemd-nspawn linux containers.<br />
                    With this technology we use VE's - virtual environments. These are software containers, running a complete operating system. These can be compared to VPS
                    systems. Administrators of the VE can run commands with root privileges, and install and finetune packages. ##&hu A szolgáltatók valamilyen technológiára
                    építenek. Nálunk ez a technológia a systemd-nspawn konténerek használata.<br />
                    Ezzel a technológiával VE azaz virtuális környezetek kialakítására van lehetőség, szoftver-konténerek melyben egy teljes operációs rendszer fut, ebben a
                    tekintetben tehát hasonlóak a VPS-ek-hez. Root jogolsultsággal lehet csomagokat telepíteni. ##
                </p>

                <h2 class="mb-4">NodeJS, Ruby, Python, Perl, PHP, Wordpress</h2>
                <p class="mb-8">
                    ##&en Many so-called "shared host" providers can run only a classic stack called LAMP, which is an acronym for Linux Apache Mysql Php. The most famous
                    application on that stack is wordpress. Of course, we can run wordpress in our containers, and many more! There is no limit on which language to use. Any
                    language, any stack, any package, any app can be used. ##&hu Sok magyar úgynevezett "shared host" szolgáltatónál csak egy klasszikus stack futtatható, az úgy
                    nevezett LAMP stack, ami a Linux Apache Mysql Php mozaikszó. A legismertebb ebben a környezetben a wordpress. Természetesen ez a stack, és ezáltal wordpress is
                    futtaható, ám ennél többre is van lehetőség. Nincs programozás-nyelvi megkötés. Gyakorlatilag bármely programozási nyelv, és bármely csomag használható! ##
                </p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "hosting",
    route:  { path: "/hosting" },
};
</script>
