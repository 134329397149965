// This is the application-module level routes file.
// Override the content according to your needs.

//*
// for example, create a default route to home
const routes = [
   /* 
    {
      	name: "home",
        path: "/",
        redirect: "/home",
    },
    */
];
//*/

// if used with dynamic componentgroup routes, import and concat the two
export default routes;
