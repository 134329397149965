// we create $app via this plugin, to make the function $app.uri and the constant $app.url available globally from app.functions.js.
// it might get extended with others by creating componentgroup js files that export other functions ...
// we use the boilerpate server and merge all from all modules

import Vue from "vue";

import functions from "@/boilerplate/app.functions.mjs";

let merged = {};
Object.values(functions).forEach(function (fno) {
    merged = {...merged, ...fno};
});

const plugin = {
    install(Vue, options) {
            Vue.prototype.$app = merged;
    },
};

Vue.use(plugin);