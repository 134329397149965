<template>
    <v-container class="dark-back text-color d-block pt-8 mt-10 mb-10">
        <v-row>
            <v-col md="4" lg="3" class="d-none d-md-block">
                <h2 class="uppercase mb-10 mt-10 underliner">##&en Web Development ##&hu Webfejlesztés ##</h2>
                <br />
                <img :src="require(`@/assets/laptop_d250.svg`)" />
            </v-col>
            <v-col md="7" lg="8" class="text-left py-4">
                <h2 class="mb-4">##&en Modern and modular ##&hu Modern és moduláris ##</h2>
                <p class="mb-8">
                    ##&en Up to date technology, custom made web-applications. Frontend and a backend development and a system engineering.
                    using our modular framework, that is built on a javascript stack using NodeJS and VueJS. ##&hu Modern technológiákra épített webes alkalmazások
                    fejlesztése, frontend-backend és rendszertevezés. Moduláris keretrendszerünk javascript alapú, NodeJS
                    backend és VueJS frontendet használ. ##
                </p>

                <h2 class="mb-4">##&en Multilingual ##&hu Többnyelvű ##</h2>
                <p class="mb-8">
                    ##&en Our applications are multilingual by default. Every modern buisness should be present on the web on the local language, and on english. In our framework
                    we have solid support built in from scratch. ##&hu A webalkalmazásaink általában többnyelvűek. Nem csak a magyar felhasználók számára, hanem a nemzetközi
                    felhasználók számára is elérhető. A keretrendszerünkbe ezt a többnyelvűséget már rögtön az elején beépítettük, mert ezt minden üzlet
                    számára fontos kritériumnak tarjuk. ##
                </p>

                <h2 class="mb-4">##&en Realtime ##&hu Valós-idejű ##</h2>
                <p class="mb-8">
                    ##&en We use the Single-Page-Application approach, and we use socket connections for the application users. This makes a fast bi-directional communication possible
                    between the Server and the browser. ##&hu A Web-alkalmazásainkban egy javascript alapú megoldást használunk, mely socket kapcsolatot hoz létre a felhasználói
                    közt. Így lehetőség nyílik valós időben, két irányban kommunikálni a szerver és a böngészők között. ##
                </p>

                <h2 class="mb-4">##&en Custom-made ##&hu Személyre-szabott ##</h2>
                <p class="mb-8">
                    ##&en Need to connect to a payment provider? Need a billing connection? Do you have another API that needs to be connected?<br />
                    This is the path to turn your Web Application into the IT platform of your company. This is not something an out of the box application can do, but these are
                    the results of development milestones. ##&hu A látogatók fizetnének? Számlát kell kiállítani? Van még egy API vagy kettő, amit integrálni kell? Ezen
                    az úton haladva válik egy web alkalmazás, a cég informatikai központjává. Ez nem egy olyan dolog amit dobozos megoldások nyújtanak, ez célzott fejlesztések
                    eredménye. ##
                </p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "development",
    route:  { path: "/development" },
};
</script>
