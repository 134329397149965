<template>
    <v-container class="contact text-color">
        <h2 class="mb-6 mt-5 uppercase underliner">##&en D250 Systems ltd. ##&hu D250 Systems Kft. ##</h2>
        <v-row class="mt-5 text-center">
            <v-col sm="4" cols="12">
                <div class="mb-3">
                    <v-btn retain-focus-on-click fab large depressed :ripple="false" class="mr-2 mb-8" outlined><v-icon>fas fa-map-marker-alt</v-icon></v-btn>
                    <div class="line-container single-line">
                        2083, Solymár,
                        <a href="https://www.google.hu/maps/place/D250+SYSTEMS/@47.5863889,18.9495584,14z/data=!3m1!4b1" target="_blank" class="styled-a">Orgona u. 9</a> ##&en
                        Hungary ##
                    </div>
                </div>
            </v-col>
            <v-col sm="4" cols="12">
                <div class="mb-3">
                    <v-btn retain-focus-on-click large fab depressed :ripple="false" class="mr-2 mb-8" outlined><v-icon>fas fa-envelope</v-icon></v-btn>
                    <div class="line-container single-line">
                        <a href="mailto:LaKing@D250.hu" class="styled-a">LaKing@D250.hu</a> / 
                        <a href="mailto:Edit@D250.hu" class="styled-a">Edit@D250.hu</a>
                    </div>
                </div>
            </v-col>
            <v-col sm="4" cols="12">
                <div class="mb-3">
                    <v-btn retain-focus-on-click large fab depressed :ripple="false" class="mr-2 mb-8" outlined><v-icon>fas fa-phone</v-icon></v-btn>
                    <div class="line-container single-line">
                        István Király <a href="tel:+36209753758" class="styled-a">+36 209 753 758</a> / Edit Józsa <a href="tel:+36306484446" class="styled-a">+36 306 484 446</a>
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "contact",
    route: true,
};
</script>
<style scoped>
.styled-a {
    color: white;
}
.line-container {
    width: 200px;
    height: 20px; /* Change height to match your desired container */
    overflow: hidden;
}
.single-line {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
}
</style>
