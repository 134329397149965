<template>
    <div class="hello relative">
        <home_top/>
        <home_services/>
    </div>
</template>

<script>

import home_services from "@/home_services.vue";
import home_top from "@/home_top.vue";

export default {
    name: "home",
    route: {
    	path: '/'
    },
    data: function () {
        return {
            ß: ß,
        };
    },
    props: {
        msg: String,
    },
    components: {
        home_top,
        home_services,
    },
    mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #22c;
}
.hello {
    min-height: var(--slideheight);
}
</style>
