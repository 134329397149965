<template>
    <v-footer dark padless>
        <v-card flat tile class="text-center light-bg w-100">
            <contact />
            <v-divider></v-divider>
            <v-card-text class="bg-1">D250 Systems Kft. ##&en HU26680774 ##&hu 26680774-2-13 ##  — last update: {{ lastupdate }} </v-card-text>
        </v-card>
    </v-footer>
</template>
<script>
import contact from "@/contact.vue";

export default {
    name: "slide_footer",
    data: function () {
        return {
            lastupdate: ß.DATE,
        };
    },
    props: {},
    components: { contact },
};
</script>
<style>
foter {
    height: 205px;
}
footer:after {
    content: "";
}
</style>