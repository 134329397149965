<template>
    <v-container class="dark-back text-color d-block pt-8 mt-10 mb-10">
        <v-row>
            <v-col md="4" lg="3" class="d-none d-md-block">
                <h2 class="uppercase mb-10 mt-10 underliner">##&en System administration ##&hu Szerver adminisztráció ##</h2>
                <br />
                <img :src="require(`@/assets/server_d250.svg`)" />
            </v-col>
            <v-col md="7" lg="8" class="text-left py-4">
                <h2 class="mb-4">##&en Open Source ##&hu Nyílt forráskód ##</h2>
                <p class="mb-8">
                    ##&en Taking a closer look on software technology, we will se two majer categories. With some shades between licensing terms, the two major categories are
                    called Open Source, and propriatery software. Software is built by compiling source code, and it matters if the source is open, available for a download or if
                    it is propriatery and a company trade secret. This implies technical properties that affect error handling and security. In the Open Source world there is no
                    artificial barrier to find a problem, and the root cause of a problem. Within propriatery software, the problem is subject to the buisness selling the product.
                    From the security standpoint, OpenSource software can go through the audit of every developer, anyone can examine security issues, which is not true for
                    propriatery software. And last but not least, Open Source code is mostly free. ##&hu Ha technikailag, technológiailag vizsgáljuk a szoftverek kérdését, két
                    kategóriával találkozunk. Persze a licenszelések közt lehetnek átmenetek, de lényegében nyílt forráskódról, és zárt forráskódról beszélhetünk. A forráskód
                    feldolgozásával, fordításával készülnek a szoftverek, és az elnevezés arra utal hogy nyíltan elérhető-e ez a kód vagy a szoftver gyártó üzleti titokként kezeli.
                    Ennek több következménye is van, ami elsősorban a hibakezelést és a biztonságot érinti. Egyrészt, hiba esetén a nyílt forráskódú rendszerekben nincs mesterséges
                    akadálya annak hogy a hibát meg lehessen találni, zárt forráskód esetén erre csak az eredei kibocsájtónak van lehetősége. Másrészt, bárki auditálhatja
                    rendszerét, megvizsgálhatja hogy vannak-e biztonsági rések, erre zárt forráskód esetén ugyanúgy nincs lehetőség. A másik nem elhanyagolható apró külömbség, hogy
                    a nyílt forráskódú szoftverek, általában ingyenesek is. ##
                </p>
                <h2 class="mb-4">Linux</h2>
                <p class="mb-8">
                    ##&en In the field of supercomputers, the only operating system used in the last 20 years. However, we don't need to go to the cuting edge supercomputers to fin
                    a Linux based operating system. Actually the word LINUX indicates that the operating system uses the linux kernel, a sophisticated, technically mature open
                    source solution at the core of the operating system. The different linux distributions use the same kernel, but focus on different aspects of use. ##&hu A
                    szupercomputerek körében az elmúlt 20 évben gyakorlatilag csak linux létezik. De nem kell a szuperkomputerek élvonalában keresgélnünk, a szerver-piacon a
                    domináns operációs rendszerek linux alapúak. A LINUX szó csupán a linux kernel-re utal, ami az operációs rendszer magja. A linux kernel technikailag kifinomult,
                    nyílt forráskódú szoftver. Erre épülnek a különféle linux disztribúciók. Ezek hasonlítanak egymásra, de mégis eltérőek, célkitűzésüknek megfelelően vannak
                    fejlesztve, különböző előnyökkel rendelkeznek másik disztribúciókkal szemben. ##
                </p>

                <h2 class="mb-6">Red Hat - Fedora</h2>
                <p class="mb-8">
                    ##&en We focus on cutting edge technology, and Red Hat's Fedora is the one in the frontline. Not only on the Server platform, we also it on Desktop systems. Red
                    hat and Fedora has been a good choice the last 20 years. A wide range of supported hardware, many contributions to the open source world, stable strategy. For
                    example systemd, the init system was available on Fedora first, then became part of literally every distribution. Developers of systemd are financed by Red Hat,
                    which was actually bought by IBM in 2019. ##&hu Mi elsősorban a Red Hat Linux élenjáró disztribúciójával, a Fedora névre hallgató Operációs Rendszerrel
                    dolgozunk. Nem is kizárólag szerver környezetben, asztali gépek terén is. A nyílt forráskódú világban jól ismert disztribúció több mint 20 éve jó választás.
                    Hardware-támogatottság, sok hozzájárulás az OpenSource, azaz a nyílt forráskód világhoz, stabil stratégia. Pl. A systemd nevű init rendszer, amit mára már
                    minden linux disztribúció átvett, elsőként Fedora operációs rendszer alatt volt elérhető. A systemd fejlesztőit a Red Hat finanszírozza. A Red Hat inc.-et
                    2019-ben megvásárolta az IBM, tehát komoly háttér van a fejlesztők mögött. ##
                </p>
                <h2 class="mb-6">##&en System Administration Services ##&hu Rendszergazdai szolgáltatások ##</h2>
                <p class="mb-8">
                    ##&en While managing our own servers, we also provide System administration services for our partners. Supervision of hardware and software 24/7, error
                    managment, timed updates, troubleshooting, optimization or security tasks. ##&hu Cégünk saját szereverinek adminisztrációja mellett megbízás alapon
                    rendszergazdai szolgálktatásokat nyújt partnereinek. Felügyeljük szerverét hardware és szoftware oldalon egyaránt, ami egy 24/7 rendelkezésre állás,
                    hibaelhárítás, valamint időzített munkálatok, általában éjszaka frissítések telepítése vagy rendzserszintű átalakítások, optimalizációs vagy biztonsági
                    feladatok. ##
                </p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "sysadmin",
    route: { path: "/sysadmin" }
};
</script>
