<template>
    <v-content dark id="main" class="dark-bg">
		<router-view/>
      
    </v-content>
</template>

<script>
    //


export default {
    name: "app_main_router_view",
    components: {
       // home_footer,
    }
};
</script>
<style scoped>

</style>
