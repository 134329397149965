// this is an automatically generated file from the ßoilerplate framework

// /srv/codepad-project/@vue-modules/app/vue;
import component_1 from '@/global-components/global-component.vue';

// /srv/codepad-project/d250-modules/app/vue;
import component_2 from '@/App.vue';

// /srv/codepad-project/d250-modules/app/vue;
import component_3 from '@/main.vue';

// /srv/codepad-project/d250-modules/app/vue;
import component_4 from '@/navigation.vue';

// /srv/codepad-project/@vue-modules/app/vue;
import component_5 from '@/notification-snackbars.vue';

// /srv/codepad-project/d250-modules/app/vue;
import component_6 from '@/bg_slide.vue';

// /srv/codepad-project/d250-modules/app/vue;
import component_7 from '@/contact.vue';

// /srv/codepad-project/d250-modules/app/vue;
import component_8 from '@/home.vue';

// /srv/codepad-project/d250-modules/app/vue;
import component_9 from '@/home_footer.vue';

// /srv/codepad-project/d250-modules/app/vue;
import component_10 from '@/home_services.vue';

// /srv/codepad-project/d250-modules/app/vue;
import component_11 from '@/home_top.vue';

// /srv/codepad-project/d250-modules/app/vue;
import component_12 from '@/home_ve_layout.vue';

// /srv/codepad-project/d250-modules/app/vue;
import component_13 from '@/page_administration.vue';

// /srv/codepad-project/d250-modules/app/vue;
import component_14 from '@/page_cloudhosting.vue';

// /srv/codepad-project/d250-modules/app/vue;
import component_15 from '@/page_development.vue';

// /srv/codepad-project/d250-modules/app/vue;
import component_16 from '@/page_infrastructure.vue';

// /srv/codepad-project/d250-modules/app/vue;
import component_17 from '@/page_ve-farm.vue';

// /srv/codepad-project/@vue-modules/multilanguage/vue;
import component_18 from '@/multilanguage-components/LanguageSelector.vue';


export default {component_1, component_2, component_3, component_4, component_5, component_6, component_7, component_8, component_9, component_10, component_11, component_12, component_13, component_14, component_15, component_16, component_17, component_18, };