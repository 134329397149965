// boilerplate generated 2024-05-19 08:42:46
import Vue from 'vue';

import Vuex from 'vuex';
import server from '@/vuex/server.js';
import socket from '@/vuex/socket.js';

Vue.use(Vuex)
export default new Vuex.Store({
strict: true,
  modules: {
	server,
	socket,
	}
})
