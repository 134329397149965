<template>
    <v-container class="dark-back text-color d-block pt-8 mt-10 mb-10">
        <v-row>
            <v-col md="4" lg="3" class="d-none d-md-block">
                <h2 class="uppercase mb-10 mt-10 underliner">##&en Cloud Hosting ##&hu Cloud Hosting ##</h2>
                <br />
                <img :src="require(`@/assets/cloud_d250.svg`)" />
                <br />
            </v-col>
            <v-col md="7" lg="8" class="text-left py-4">
                <div class="d-flex">
                    <img :src="require('@/assets/ve_white.svg')" class="ve-logo" />
                    <div>
                        <h1 class="display-1 white--text font-weight-bold">##&en Containerfarm ##&hu Konténerfarm ##</h1>
                        <div class="header-text">##&en From developers, for developers ##&hu Fejlesztőktől fejlesztőknek ##</div>
                    </div>
                </div>
                <br />
                <p class="mb-8">
                    ##&en Container technology, with lightweight virtual environments. A kind of VPS or VM but without hardware emulation. ##&hu Konténertechnológia, könnyű
                    virtuális környezetek. Olyan mint egy VPS vagy VM csak hardware emuláció nélkül. ## 
                   <h1> 800% CPU</h1><h1>16GB RAM</h1><h1>80GB SSD</h1><br/>                   
                    ##&en The avarage cpu usage in a container is around 10% and about 1GB ram. We set the limits higher, so your app can get a boost if needed. ##&hu A konténerek átlagosan 10% CPU kapacitást és 1GB RAM-ot használnak. De hogyha szükség lenne rá, ezt mind használhatják. ##<br />
               
                </p>
            	<br />
                <div v-for="(t, i) in tech" @click.native="open(t.link)">
                    <h2 class="mb-4">
                        <v-icon dark x-large>{{ t.icon }}</v-icon> - {{ t.title }}
                    </h2>
                    <p class="mb-8">
                        {{ t.text }}
                    </p>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "ve-farm",
    route: { path: "/ve-farm" },
    data: function () {
        return {
            tech: [
                {
                    title: "##&en Linux Kernel ##&hu Linux Kernel ##",
                    icon: "fab fa-linux",
                    text: "##&en We can run anything that runs on the linux kernel. ##&hu Minden futtatható ami a linux kernel alatt futtatható. ##",
                },
                {
                    title: "##&en Fedora Distribution ##&hu Fedora Disztribúció ##",
                    icon: "fab fa-fedora",
                    text: "##&en The host, and the containers run on Fedora by default. ##&hu Fedora disztribúciós hoszt, és alapértelmezett környezetek. ##",
                },
                {
                    title: "##&en Systemd containers ##&hu Systemd konténerek ##",
                    icon: "fab fa-redhat",
                    text: "##&en VE's are run with the systemd-nspawn container technology ##&hu VE - azaz a virtuális környezet systemd-nspawn konténer. ##",
                },
                {
                    title: "##&en Node.js is available ##&hu Node.js támogatás ##",
                    icon: "fab fa-node",
                    text: "##&en JavaScript runtime for the server. ##&hu A szerver oldali Javascript motor, elérhető minden konténerben. ##",
                },
                {
                    title: "##&en Node Package Manager ##&hu Node csomagkezelő ##",
                    icon: "fab fa-npm",
                    text: "##&en Install anything with npm, or any other package manager. ##&hu bármi telepíthető, npm-el, vagy bármely csomagkezelővel. ##",
                },
                {
                    title: "##&en PHP is available ##&hu PHP is futtatható ##",
                    icon: "fab fa-php",
                    text: "##&en Support for the popular general-purpose scripting language. ##&hu A szerver oldali szkriptnyelv is könnyen telepíthető. ##",
                },
                {
                    title: "##&en Wordpress ##&hu Wordpress ##",
                    icon: "fab fa-wordpress",
                    text: "##&en Create a wordpress container with a few clicks. ##&hu Egy wordpress konténer létrehozása néhány klikk. ##",
                },
                {
                    title: "##&en Java ##&hu Java ##",
                    icon: "fab fa-java",
                    text: "##&en Install OpenJDK, or the Oracle java SE. ##&hu Akár OpenJDK, akár Oracle Java SE. ##",
                },
                {
                    title: "##&en Shell access ##&hu Shell hozzáférés ##",
                    icon: "fab fa-github",
                    text:
                        "##&en Install anything straight from github, with native or browser shell. ##&hu Bármi telepíthető, akár egyenesen githubról is, natív vagy webes shell-el. ##",
                },
            ],
        };
    },
};
/*
    
                    <v-simple-table dark>
                    <thead>
                        <tr>
                            <th class="text-left">Name</th>
                            <th class="text-left">Calories</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>2</td>
                        </tr>
                    </tbody>
                </v-simple-table>
    */
</script>
