<template>
    <section class="hello relative">
        <div class="absolute bg-img w-100 fill-height"></div>
        <v-container fill-height>
            <v-layout>
                <v-row justify="center" no-gutters>
                    <v-col lg="6" class="pa-5 pr-lg-10" cols="12">
                        <ve_layout class="v-card theme--dark layouts cardbox" />
                    </v-col>

                </v-row>
            </v-layout>
        </v-container>
    </section>
</template>

<script>
/*
                    <v-col lg="6" class="pa-5 pl-lg-10" cols="12">
                        <loginlayout class="v-card theme--dark layouts cardbox" />
                    </v-col>

*/
    
    
//import bg_slide from "@/bg_slide.vue";
//import loginlayout from "@/components/LoginLayout.vue";
import ve_layout from "@/home_ve_layout.vue";
    
// import lab from "@/lab.vue";

export default {
    name: "slide_hello",
    data: function () {
        return {
        };
    },
    props: {
        msg: String,
    },
    components: {
        //loginlayout,
        ve_layout
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>


</style>
