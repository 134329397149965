<template>
    <div id="snackbars">
        <v-snackbar id="error" v-model="error" timeout="5000" color="red" top>
            {{ err }}
       </v-snackbar>
        <v-snackbar id="notice" v-model="notice" timeout="5000" color="deep-orange" multi-line min-width="500" min-height="500" top>
            {{ ntc }}
        </v-snackbar>
        <v-snackbar id="message" v-model="message" timeout="5000" color="green" multi-line min-width="500" min-height="500" top>
            {{ msg }}
        </v-snackbar>
        <v-snackbar id="snackbar" v-if="enabled" v-model="snackbar" timeout="5000" :color="snackbarColor" multi-line @click.native="snackbar = false">
            {{ snackbarMessage }}
        </v-snackbar>
    </div>
</template>

<script>
    
export default {
    name: "snackbars",
    props: {
        enabled: {
            default: true,
        },
    },
    data: function () {
        return {
            notice: false,
            error: false,
            message: false,
            snackbar: false,
            snackbarMessage: "",
            snackbarColor: "",
            ntc: "",
            err: "",
            msg: "",
        };
    },
    sockets: {
        snackbar_error(msg) {
            console.log("snackbar error", msg);
            this.snackbarMessage = msg;
            this.snackbarColor = "#A00";
            this.snackbar = true;
        },
        snackbar_warning(msg) {
            console.log("snackbar warning", msg);
            if (this.snackbar) return;
            this.snackbarColor = "#E51";
            this.snackbarMessage = msg;
            this.snackbar = true;
        },
        snackbar_message(msg) {
            console.log("snackbar message", msg);
            if (this.snackbar) return;
            this.snackbarColor = "#030";
            this.snackbarMessage = msg;
            this.snackbar = true;
        },
        error(msg) {
            console.log("error", msg);
            this.err = msg;
            this.error = true;
        },
        notice(msg) {
            console.log("notice", msg);
            this.ntc = msg;
            this.notice = true;
        },
        message(msg) {
            console.log("message", msg);
            this.msg = msg;
            this.message = true;
        },
    },
};
</script>

<style scoped>
#snackbar {
    padding-bottom: 50px !important;
}

</style>
